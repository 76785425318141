@use 'common'

$filterBorderRadius: var(--border-radius)
$filterVerticalPadding: .15em
$filterHorizontalPadding: .5em

=hover-focus-styles
	background-color: common.$c-red
	color: common.$c-white

=filter-styles
	border: 1px solid common.$c-red
	white-space: nowrap
	font-size: 18px
	line-height: 1.2
	transition-property: background-color, color
	transition-duration: .2s

.wrapper
	//

	&.is_open

		.select
			&Header
				&::after
					transform: rotate(180deg)

			&Overlay
				z-index: 1

		.options
			visibility: initial
			opacity: 1

.title
	margin-bottom: .25em
	font-size: 1.125em
	color: common.$c-grey-semidark

.list
	display: flex

.item
	+filter-styles

	background-color: rgba(common.$c-white, .5)

	&:not(:first-child)
		margin-left: -1px

	&:first-child
		border-top-left-radius: $filterBorderRadius
		border-bottom-left-radius: $filterBorderRadius

	&:last-child
		border-top-right-radius: $filterBorderRadius
		border-bottom-right-radius: $filterBorderRadius

.select
	$select: &

	width: 160px
	display: inline-block
	position: relative
	border-radius: $filterBorderRadius
	outline: none
	cursor: pointer
	background-color: transparent
	padding: 0
	border: 0

	&:hover &
		&Header
			+hover-focus-styles

	&:focus-visible &
		&Header
			+hover-focus-styles

	&Overlay
		position: fixed
		inset: 0
		background-color: transparent
		border: initial
		outline: initial
		padding: 0
		z-index: -1

	&Header
		+filter-styles

		width: 100%
		border-radius: $filterBorderRadius
		padding: $filterVerticalPadding $filterHorizontalPadding
		padding-right: 36px
		text-align: left

		&::after
			content: '▾'
			display: block
			position: absolute
			top: 50%
			right: .5em
			line-height: 0
			pointer-events: none
			opacity: .5
			transition: transform .2s

.options
	background-color: common.$c-white
	white-space: pre
	position: absolute
	top: calc(100% + 2px)
	left: 1px
	right: 1px
	z-index: 2
	box-shadow: 0px 3px 10px rgb(0 0 0 / 40%)
	visibility: hidden
	opacity: 0
	transition-property: visibility, opacity
	transition-duration: .2s

.option
	font-size: 18px

.link
	display: block
	color: common.$c-grey-semidark
	padding: $filterVerticalPadding $filterHorizontalPadding
	text-decoration: none
	transition-property: background-color, color
	transition-duration: .2s
	outline: unset

	&:hover
		+hover-focus-styles

	&:focus-visible
		+hover-focus-styles

.list, .optionsList
	list-style: none

.option, .item

	&.is_active
		background-color: common.$c-red

		.link
			color: common.$c-white
