@use 'common'

.wrapper
	//

.title
	//

.text
	margin-bottom: 20px

.form
	position: relative

.paymentOptions
	padding-top: 10px
	padding-bottom: 10px

.submit
	font-size: 19px
