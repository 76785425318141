@use 'common'

$gap: 20px
$buttonSize: 32px

.wrapper
	max-width: 360px + ($gap * 2) + ($buttonSize * 2)

.title
	font-size: 25px
	font-weight: 600
	text-align: center
	margin-bottom: 25px

	@media (min-width: common.$break768)
		font-size: 30px

.main
	display: flex
	align-items: center
	gap: $gap

.slides
	+common.hideHorizontalScrollbar()
	scroll-snap-type: x mandatory
	display: flex
	align-items: center

.slide
	width: 100%
	height: 100%
	flex-shrink: 0
	scroll-snap-align: start
	font-size: 15px
	line-height: 1.46
	text-align: center
	max-height: 240px
	overflow: hidden
	position: relative
	z-index: 0

	&.is_expanded
		max-height: initial

	&In
		//

.expandWrapper
	background-color: transparent
	margin: unset
	border: unset

	position: absolute
	right: 0
	bottom: 0
	left: 0
	font-size: 12px
	padding: 5em 0 1em
	text-align: center
	font-weight: 700
	cursor: pointer
	background: linear-gradient(rgba(250,250,250,0) 0,#fafafa 90%)

	&:hover, &:focus-visible
		.expandText
			opacity: 1

.expandText
	color: common.$c-main
	opacity: 0.5
	font-style: italic
	font-size: inherit
	font-weight: 700
	transition: all .2s

.button
	width: $buttonSize
	height: $buttonSize
	font-size: 18px
	padding: 0
	border: initial
	pointer-events: auto
	border-radius: 50%
	z-index: 0
	color: common.$c-black
	display: flex
	justify-content: center
	align-items: center
	padding-right: 3px
	cursor: pointer
	flex-shrink: 0
	background-color: common.$c-white
	box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.25)

	&.view_next
		transform: scaleX(-1)
