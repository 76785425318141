@use 'common'

$borderRadius: var(--border-radius)

.wrapper
	display: flex

.list
	display: flex
	flex-wrap: wrap
	list-style: none
	font-size: 16px

.item
	overflow: hidden

	&:not(:first-child)
		border-left: 1px solid common.$c-grey-light

	&:first-child
		border-top-left-radius: $borderRadius
		border-bottom-left-radius: $borderRadius

	&:last-child
		border-top-right-radius: $borderRadius
		border-bottom-right-radius: $borderRadius
