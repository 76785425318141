@use 'common'

$iconColumnWidth: 60px
$gapidth: 30px

.wrapper
	//

.item
	@media (min-width: common.$break480)
		display: grid
		grid-template-columns: $iconColumnWidth auto
		grid-template-rows: auto auto
		column-gap: $gapidth

	@media (min-width: common.$break992)
		margin-left: -$iconColumnWidth - $gapidth

.icon
	display: none

	@media (min-width: common.$break480)
		display: flex
		width: $iconColumnWidth
		font-size: 60px
		color: #D82121
		align-self: center
		grid-row: 2

.main
	width: 100%

.link
	display: inline-block
	width: 100%
	height: 100%
	position: relative

.image
	width: 100%
	height: 200px
	position: relative
	background-color: common.$c-grey
	grid-column: 2

	&In
		object-fit: cover

.content
	position: relative
	display: flex
	flex-direction: column
	align-items: center
	gap: 50px
	padding: 20px 28px
	border: 1px solid #cdcdcd
	background-color: common.$c-white
	z-index: 0

	@media (min-width: common.$break480)
		&::before
			content: ''
			width: 20px
			height: 20px
			position: absolute
			left: 0
			top: 50%
			transform: translate(-50%, -50%) rotate(45deg)
			border-left: 1px solid #cdcdcd
			border-bottom: 1px solid #cdcdcd
			background-color: common.$c-white
			z-index: 0

	@media (min-width: common.$break768)
		flex-direction: row

.date
	font-weight: 900

	@media not all and (min-width: common.$break768)
		text-align: center

	@media (min-width: common.$break768)
		align-self: flex-start

	&Day
		font-size: 37px
		line-height: 1em

	&Month
		font-size: 16px
		line-height: 1em

.info
	width: 100%

.event
	&Title
		font-size: 24px
		margin: unset

		&:not(:first-child)
			margin-top: 40px

		@media not all and (min-width: common.$break768)
			text-align: center

	&Schedule
		//
