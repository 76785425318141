@use 'common'

.wrapper
	padding-bottom: common.$siteContentPaddingBottom

.content
	font-size: 18px
	line-height: 1
	font-weight: 300
	text-align: center
	margin-bottom: 2rem

.filter
	margin-bottom: 40px

.products
	margin-bottom: 60px

.orderForm
	max-width: 30rem
	margin: 0 auto
