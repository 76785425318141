@use "common"
@use "sass:math"

=header-hover-focus-style
	transform: translateY(-5px)

.wrapper
	background-color: common.$text-color
	color: common.$c-white

	&.is_newspaperPage
		background-color: transparent
		color: common.$c-black

		.minorTv
			display: none

.main
	display: flex
	justify-content: space-between
	align-items: center

.leftSide
	display: flex

.minorTv // @TODO: add animation
	display: inline-block
	width: 65px
	height: 65px
	position: relative
	margin-left: 10px

	&Image
		object-fit: cover
		height: 100%
		width: 100%

.navigations
	display: flex
	align-items: center

.secondaryNavigation
	padding-right: 40px
	padding-left: 40px

	@media (max-width: (common.$break992 - 1))
		display: none

.logo
	outline: none
	display: flex
	width: 115px
	transition: transform .2s

	&Image
		width: 100%
		height: auto

	// styles for hover & focus-visible are separated because safari currently doesn't support focus-visible
	&:hover
		+header-hover-focus-style

	&:focus-visible
		+header-hover-focus-style
