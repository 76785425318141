@use 'common'

.wrapper
	$size: 1.75em

	display: inline-block
	min-width: $size
	height: $size
	padding: 0 .2em
	font-size: 18px
	line-height: $size
	border: 1px solid common.$c-main
	border-radius: 2px
	text-align: center

	@media (min-width: common.$break480)
		font-size: 20px
