@use 'common'

.wrapper
	//

.content
	//

.main
	padding: 25px
