@use 'common'

.wrapper
	//

.list
	//

.item
	&:not(:first-child)
		margin-top: 38px

.emptyProgram
	margin: 50px 0
	font-size: 24px
	text-align: center

	@media (min-width: common.$break768)
		margin: 150px 0 100px

.content
	//

.newsletter
	margin-top: 50px
	text-align: center

	&Info
		font-size: 20px

	&Form
		display: flex
		justify-content: center
		font-size: common.$base-font-size

	@media (min-width: common.$break768)
		margin-top: 100px
