@use 'common'

.wrapper
	width: 100%
	display: flex
	justify-content: space-between
	align-items: center
	padding: 0 3.125rem
	pointer-events: none

.button
	$buttonSize: 32px

	width: $buttonSize
	height: $buttonSize
	font-size: 18px
	padding: 0
	background-color: rgba(common.$c-black, 0.3)
	border: initial
	pointer-events: auto
	border-radius: 50%
	opacity: .3
	transition-property: visibility, opacity, background-color
	transition-duration: .2s
	z-index: 2
	color: common.$c-white
	cursor: not-allowed
	display: flex
	justify-content: center
	align-items: center
	padding-right: 3px

	&.is_visible
		opacity: 1
		cursor: pointer

		&:hover, &:focus-visible
			background-color: common.$c-black

	&.view_previous
		//

	&.view_next
		transform: scaleX(-1)
