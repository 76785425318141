@use 'common'

.wrapper
	padding: 20px

	@media (min-width: common.$break992)
		padding: 30px 90px 40px

.title
	text-align: center
	font-size: 30px

	@media (min-width: common.$break768)
		font-size: 40px

.list
	//

.item

	&:not(:first-child)
		margin-top: 60px
