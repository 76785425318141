@use 'common'

.wrapper
	display: inline-block

.tag
	display: inline-block
	margin-bottom: .2em
	margin-right: .2em
	line-height: 0
	vertical-align: middle
