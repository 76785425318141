@use 'common'
@use './GridTileFull.module' as gridTileFull

.wrapper
	flex-grow: 1
	flex-shrink: 1

.body
	margin: 10px 15px

	@media (min-width: common.$break768)
		margin: 30px 35px

.title
	font-family: common.$font-family-base
	font-weight: 900
	font-size: 25px
	line-height: 1.3
	margin-bottom: .25em

	@media (min-width: common.$break768)
		font-size: 50px

.text
	font-size: 15px
	line-height: 1.7

.actions
	display: flex
	margin-top: 10px

	@media (min-width: common.$break768)
		margin-top: 30px
		font-size: 20px
