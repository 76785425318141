@use "common"

$verticalSpacing: 25px

=copyrightLink-hover-focus-style
	text-decoration: none

	.copyright
		&Hover
			opacity: 1
			visibility: initial
			transform: translate(calc(200% + 10px), -50%)

			@media (min-width: common.$break992) and (max-width: 1080px)
				transform: translate(-50%, -25px)

.wrapper
	background-color: common.$c-black

.main
	display: flex
	flex-direction: column
	align-items: center
	justify-content: center
	text-align: center
	padding-top: $verticalSpacing
	padding-bottom: $verticalSpacing

	@media (min-width: common.$break992)
		flex-direction: row
		justify-content: space-between
		align-items: flex-start
		flex-wrap: wrap
		text-align: initial

.column

	&Title
		font-size: 21px
		line-height: 1.3
		color: common.$c-white

	&:not(:first-child)
		margin-top: $verticalSpacing

	@media (min-width: common.$break992)
		&:not(:first-child)
			margin-top: 0

	&.view_align_right
		@media (min-width: common.$break992)
			text-align: right

.navigation
	//

.socials
	margin-bottom: $verticalSpacing

.partners
	&Link
		color: common.$c-white

		&:hover, &:focus-visible
			text-decoration: none

.copyright
	width: 100%
	margin-top: 10px
	padding-top: 10px
	padding-bottom: 10px
	border-top: 1px solid common.$c-border
	font-weight: 300
	color: common.$c-white

	&Link
		position: relative
		display: inline-block
		outline: none

		&:hover
			+copyrightLink-hover-focus-style

		&:focus-visible
			+copyrightLink-hover-focus-style

	&Hover
		position: absolute
		top: 50%
		left: 50%
		visibility: hidden
		opacity: 0
		transform: translate(-50%, -50%)
		transition-property: transform, visibility, opacity
		transition-duration: .2s

	@media (min-width: common.$break992)
		text-align: right
