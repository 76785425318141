@use "common"

=menuButton-hover-focus-style
	outline: none
	background-color: common.$c-menu-button-hover
	color: common.$c-white

.menuButton
	display: flex
	justify-content: center
	align-items: center
	padding: 5px 12px
	font-size: 20px
	border: 1px solid common.$c-grey
	border-radius: 4px
	background-color: transparent
	text-transform: uppercase
	cursor: pointer
	font-family: common.$font-family-base
	color: inherit
	z-index: 45
	transition-property: background-color, color
	transition-duration: .2s

	&.is_active
		z-index: 50
		color: common.$c-white

	&Label
		display: none

	// styles for hover & focus-visible are separated because safari currently doesn't support focus-visible
	&:hover
		+menuButton-hover-focus-style

	&:focus-visible
		+menuButton-hover-focus-style

	@media (min-width: common.$break992)
		&Label
			display: block

.hamburger
	display: inline-block
	position: relative
	width: 30px
	height: 30px

	&Line
		position: absolute
		display: block
		width: 100%
		height: 2px
		background-color: common.$c-grey

		&:first-child
			top: 5px

		&:nth-child(2)
			top: 50%
			transform: translateY(-50%)
			transition-property: opacity, visibility
			transition-duration: .2s

		&:last-child
			bottom: 5px

		&:first-child, &:last-child
			transition: transform .4s

	&.is_active
		.hamburgerLine

			&:first-child
				transform-origin: 6px
				transform: rotate(45deg) translateX(4px)

			&:nth-child(2)
				opacity: 0
				visibility: hidden

			&:last-child
				transform-origin: 6px
				transform: rotate(-45deg) translateX(4px)

	@media (min-width: common.$break992)
		margin-left: 10px
