@use 'common'

.wrapper
	--AsideMediaContentBlock-wrapper-gap: 30px

	display: flex
	flex-direction: column
	gap: var(--AsideMediaContentBlock-wrapper-gap)

	@media (min-width: common.$break560)
		flex-direction: row
		align-items: center

	@media (min-width: common.$break768)
		--AsideMediaContentBlock-wrapper-gap: 50px

.content

	@media (min-width: common.$break560)
		width: calc(50% - (var(--AsideMediaContentBlock-wrapper-gap) / 2))

.title
	font-size: 40px
	line-height: 1.25

	@media (min-width: common.$break768)
		font-size: 60px

.figure
	margin: unset

	&Description
		text-align: center

	@media (min-width: common.$break560)
		width: calc(50% - (var(--AsideMediaContentBlock-wrapper-gap) / 2))

.image
	width: 100%
	height: auto
