@use 'common'

=tableWithLinksContent-hover-focus-style
	text-decoration: none

.link
	color: inherit
	outline: none

	&:hover
		+tableWithLinksContent-hover-focus-style

	&:focus-visible
		+tableWithLinksContent-hover-focus-style
