@use 'common'

.wrapper
	//

.content
	//

.main
	display: flex
	flex-direction: column
	padding: 0 25px

	@media (min-width: common.$break992)
		flex-direction: row
		gap: 3rem
		padding: 0 40px

.switcher
	$switcherBottomSpacing: calc(2 * var(--page-vertical-spacing))

	margin-bottom: $switcherBottomSpacing
