@use "common"

$personHorizontalSpacing: 20px
$personVerticalSpacing: 40px

.wrapper
	margin: auto

.list
	display: grid

	@media (min-width: common.$break380)
		grid-template-columns: 1fr 1fr
		grid-auto-flow: dense

	@media (min-width: common.$break560)
		grid-template-columns: 1fr 1fr 1fr

	@media (min-width: common.$break768)
		grid-template-columns: minmax(0, calc((100% - 800px) / 2)) 1fr 1fr 1fr 1fr minmax(0, calc((100% - 800px) / 2))

.emptyDivToFillInGridOnDesktop
	display: none

	@media (min-width: common.$break768)
		display: block
