@use 'common'

.wrapper
	width: 100%
	padding: 10px 20px
	background-color: common.$c-grey-light6
	position: relative

	&.view_newsletter
		text-align: left
		padding: 25px

		.title
			font-size: 24px

		.title, .text
			margin-bottom: 6px

.form
	//

	@media (min-width: common.$break480)

		.name
			display: flex
			gap: 12px

	&.is_loading
		background-color: black
