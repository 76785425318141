@use 'common'

.wrapper
	display: flex
	flex-direction: column
	gap: 2em

.primaryText
	font-size: 1.4em

.secondaryText
	font-size: 1.1em
