@use 'common'

$itemVerticalSpacing: 10px

.wrapper
	//

.list
	margin-top: $itemVerticalSpacing
	list-style: none

.item
	padding: $itemVerticalSpacing 0

	&.view_hideBottomSpacing
		padding-bottom: 0

	&.view_collapsible
		&:first-child
			margin-top: $itemVerticalSpacing
			border-top: 1px solid common.$c-grey-light2
			padding-top: $itemVerticalSpacing

	&:first-child
		padding-top: 0

	&:last-child
		padding-bottom: 0

	&:not(:first-child)
		border-top: 1px solid common.$c-grey-light2

.description
	padding-top: $itemVerticalSpacing
	border-top: 1px solid common.$c-grey-light2

.buttonCollapse
	display: flex
	justify-content: center
	margin-top: $itemVerticalSpacing
	padding-top: $itemVerticalSpacing
	border-top: 1px solid common.$c-grey-light2

	@media (min-width: common.$break768)
		justify-content: flex-start
