@use 'common'

.wrapper
	//

.button
	width: 100%
	cursor: pointer
	background-color: transparent
	border: unset
	outline: none
	position: absolute
	inset: 0

	&:hover, &:focus
		.icon
			transform: translateY(-10px)

.image
	object-fit: cover

.content
	display: flex
	flex-direction: column
	justify-content: flex-end
	padding: 20px 10px
	position: absolute
	z-index: 0
	inset: 0

	&::before
		content: ''
		position: absolute
		inset: 0
		z-index: 1
		background: linear-gradient(0deg, common.$c-black, transparent 50%)

.icon
	transition: transform .2s
	font-size: 2.5em
	color: common.$c-white
	z-index: 1

	@media (min-width: common.$break768)
		font-size: 3em

.title
	margin: 0
	z-index: 1
	font-size: 24px
	color: common.$c-white
