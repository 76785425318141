@use 'common'
@use 'sass:math'

.wrapper
	//

.list
	display: flex
	justify-content: center
	align-items: center
	gap: 30px

.item
	//

.link
	display: block
	background-color: common.$c-grey
	transition: transform .2s

	&:hover, &:focus
		transform: scale(1.05)

.icon
	position: relative
	$aspectRatio: math.div(45, 24)

	width: 45px

	&In
		object-fit: cover

	&::before
		content: ''
		display: block
		padding-top: math.div(100%, $aspectRatio)
