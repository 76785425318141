@use "common"

.wrapper
	//

.department
	padding-top: 50px
	padding-bottom: 50px

	&:nth-child(2n - 1)
		background-color: common.$c-grey-light3

.title
	text-align: center
