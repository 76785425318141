@use 'common'
@use 'sass:math'

.wrapper
	width: 100%
	height: 100%
	font-family: common.$font-jz

.main
	min-height: 100vh
	display: flex
	flex-direction: column

.content
	padding-top: 5vh
	padding-bottom: 5vh

.title
	font-size: 34px
	text-align: center
	font-family: common.$font-jz

	@media (min-width: common.$break768)
		font-size: 6vw

.text
	text-align: center
