@use 'common'

.wrapper
	//

.image
	width: 150px
	height: 150px

.view_imageCenter
	.image
		margin: 0 auto
