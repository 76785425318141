@use 'common'
@use 'sass:color'

$iconTranslateWidth: 0.15em

.wrapper
	display: flex
	align-items: center
	position: relative
	height: 100%
	border: none
	background-color: var(--Button-base-color)
	margin: 0
	font-size: inherit
	font-weight: inherit
	text-align: inherit
	color: var(--Button-other-color)
	cursor: pointer
	text-transform: inherit
	text-decoration: none
	padding: .3em 1em .4em
	border-radius: 2px
	border: 1px solid var(--Button-base-color)
	font-weight: 600
	line-height: 1
	text-align: center
	transition: background-color .2s
	outline: none
	font-family: common.$font-family-base

	.icon
		display: flex
		font-size: inherit
		margin-left: .5em
		transition: transform .2s

		@keyframes shiftUp
			0%
				transform: translateY(0em)

			50%
				transform: translateY(-1 * $iconTranslateWidth)

			100%
				transform: translateY(0em)

		@keyframes shiftRight
			0%
				transform: translateX(0em)

			50%
				transform: translateX($iconTranslateWidth)

			100%
				transform: translateX(0em)

		@keyframes shiftDown
			0%
				transform: translateY(0em)

			50%
				transform: translateY($iconTranslateWidth)

			100%
				transform: translateY(0em)

		@keyframes shiftLeft
			0%
				transform: translateX(0em)

			50%
				transform: translateX(-1 * $iconTranslateWidth)

			100%
				transform: translateX(0em)

	&:hover
		.icon

			&.up
				transform: translateY(-1 * $iconTranslateWidth)

			&.right
				transform: translateX($iconTranslateWidth)

			&.down
				transform: translateY($iconTranslateWidth)

			&.left
				transform: translateX(-1 * $iconTranslateWidth)

			&.isLooped
				animation-duration: 1s
				animation-timing-function: ease
				animation-iteration-count: infinite

				&.up
					animation-name: shiftUp

				&.right
					animation-name: shiftRight

				&.down
					animation-name: shiftDown

				&.left
					animation-name: shiftLeft

	&:focus-visible
		.icon

			&.isLooped
				&.down
					animation-name: shiftDown
					animation-timing-function: ease
					animation-duration: 1s

	&.view_red
		--Button-base-color: #{common.$c-red}
		--Button-other-color: #{common.$c-white}
		text-shadow: 0 1px 2px rgba(#000000, .3)

		&:hover, &.is_active
			background-color: darken(common.$c-red, 15%)

		&:focus-visible
			background-color: darken(common.$c-red, 15%)

		&.is_disabled
			&:hover, &.is_active
				background-color: var(--Button-base-color)
				cursor: not-allowed

			&:focus-visible
				background-color: var(--Button-base-color)
				cursor: not-allowed

	&.view_grey
		--Button-base-color: #{common.$c-grey}
		--Button-other-color: #{common.$c-white}
		text-shadow: 0 1px 1px rgba(#000000, .2)

		&:hover, &.is_active
			background-color: darken(common.$c-grey, 15%)

		&:focus-visible
			background-color: darken(common.$c-grey, 15%)

		&.is_disabled
			&:hover, &.is_active
				background-color: var(--Button-base-color)
				cursor: not-allowed

			&:focus-visible
				background-color: var(--Button-base-color)
				cursor: not-allowed

	&.view_xs
		font-weight: 400
		padding: 5px 10px

	&.view_medium
		font-weight: 700
		padding: 15px 30px

	&.view_tab, &.view_tabSmall, &.view_program
		--Button-base-color: #{common.$c-white}
		--Button-other-color: #{common.$c-grey-dark2}

		max-width: 18em
		text-transform: uppercase
		padding: .5em 2em
		border: none
		box-shadow: inset 0 -2px common.$c-grey-light2

		&:hover, &:active, &.is_active
			--Button-base-color: #{common.$c-blue}
			--Button-other-color: #{common.$c-white}
			box-shadow: inset 0 2px rgba(common.$c-black, .1)

		&:focus-visible
			--Button-base-color: #{common.$c-blue}
			--Button-other-color: #{common.$c-white}
			box-shadow: inset 0 2px rgba(common.$c-black, .1)

	&.view_program
		line-height: common.$gratio

	&.is_outline
		background-color: transparent
		color: var(--Button-base-color)
		border: 1px solid var(--Button-base-color)

		&.is_disabled
			&:hover, &.is_active
				background-color: transparent
				cursor: not-allowed

			&:focus-visible
				background-color: transparent
				cursor: not-allowed

	&.is_bordered
		background-color: transparent
		border: solid 1px

	&.is_shadowed
		box-shadow: 0 2px 16px rgba(#000000, .2), 0 2px 4px rgba(#000000, .2)

	&.is_sidespace
		padding: .5em 2.5em .6em

	&.is_borderRadiusUnset

		&All
			border-radius: unset
		&Top
			border-top-right-radius: unset
			border-top-left-radius: unset
		&Right
			border-top-right-radius: unset
			border-bottom-right-radius: unset
		&Bottom
			border-bottom-right-radius: unset
			border-bottom-left-radius: unset
		&Left
			border-top-left-radius: unset
			border-bottom-left-radius: unset

	&.is_disabled
		&:hover, &.is_active
			background-color: var(--Button-base-color)
			cursor: not-allowed

		&:focus-visible
			background-color: var(--Button-base-color)
			cursor: not-allowed

	&.view_tab, &.view_tabSmall
		border-radius: 2px

	&.view_tab
		font-size: 1.5em

	&.view_tabSmall
		font-size: 1.2em

	&.view_pageTileLinkItem
		padding: 15px 25px
		border-color: unset
		font-weight: 400
		border-radius: 0

		@media (min-width: common.$break992)
			padding-top: 5px
			padding-bottom: 5px

		&:hover
			background-color: #{common.$c-red}

		&:focus-visible
			background-color: #{common.$c-red}

		&::before
			content: ''
			position: absolute
			right: 0
			bottom: 0
			left: 0
			height: 3px
			background-color: rgba(common.$c-black, .3)
			border-radius: 0 0 3px 3px

	&.view_program

		@media (max-width: common.$break768 - 1px)
			padding: .5em .5em

	&.view_programAndTicketPageEvent
		padding: 10px 25px
		min-width: 120px
		justify-content: center

	&.view_newsletterBox
		width: 100%
		font-size: 19px
		font-weight: 500
		letter-spacing: .5px
		padding: 15px 10px
		text-transform: none
		justify-content: center

	&.view_detailUpcomingEvent
		padding: 8px 45px
		font-weight: 400
		font-size: 19px
		background-color: color.adjust(common.$c-red, $lightness: -7%)
