.wrapper
	width: 150px
	margin: auto
	text-align: center

.name
	font-size: 22px
	font-weight: normal
	margin-top: 15px
	margin-bottom: 5px

.contact
	font-size: 15px
	line-height: 1.4
	font-weight: 300

.telephone, .email
	display: inline-block
	outline: none

	&:hover, &:focus
		text-decoration: none
