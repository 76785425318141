@use 'common'

$heroContentMaxWidth: 460
$heroImageWidthRatio: calc(((common.$siteWidth - 490) / common.$siteWidth) * 100%)
$elementBottomSpacing: 25px

.wrapper
	max-width: calc(var(--content-width-wide) + 2 * var(--page-horizontal-spacing))
	width: 100%
	margin-left: auto
	margin-right: auto
	padding: var(--page-vertical-spacing) var(--page-horizontal-spacing)

	@media (min-width: common.$break992)
		display: flex
		justify-content: center
		flex-direction: row-reverse
		gap: 30px

		@media (min-width: common.$break1200)
			gap: 50px

.column

	&.is_media
		@media (min-width: common.$break992)
			flex-grow: 1

	&.is_content

		@media (min-width: common.$break992)
			max-width: 460px

.media

	@media (min-width: common.$break992)
		position: sticky
		top: 20px
		z-index: 1

.gallery

	@media not all and (min-width: common.$break992)
		margin: calc(-1 * var(--page-vertical-spacing)) calc(-1 * var(--page-horizontal-spacing)) 0

.partners
	margin-top: 20px

.upcomingProgram
	margin-left: auto
	margin-top: 1.25rem

	&In
		@media (min-width: common.$break992)
			max-width: 450px
			margin-left: auto
			pointer-events: all

		@media not all and (min-width: common.$break992)
			margin-bottom: 20px

	@media (min-width: common.$break992) and (max-width: common.$siteWidthWide - 1px)
		padding-inline: var(--page-horizontal-spacing)

	@media (min-width: common.$break992)
		position: fixed
		bottom: var(--page-vertical-spacing)
		right: 0
		left: 0
		max-width: var(--content-width-wide)
		margin-inline: auto
		width: 100%
		z-index: 0
		pointer-events: none

.title
	font-size: 40px
	line-height: 1.25
	margin-bottom: $elementBottomSpacing

	@media (min-width: common.$break480)
		font-size: 60px

.tags
	margin-bottom: $elementBottomSpacing

.tableSpecification
	margin-top: 120px - $elementBottomSpacing
