@use 'common'

.wrapper
	//

.list
	list-style: none

.item
	//

.order
	min-width: 6em
	width: 6em
	height: 6em
	margin: 0 auto 20px
	border-radius: 50%
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	border: 1px solid common.$c-grey-light3

	&Number
		font-size: 2.8em
		font-weight: 700
		padding-left: .125em
		line-height: 1
		color: common.$c-grey-dark3

	&Label
		//
