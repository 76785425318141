@use 'common'

$buttonHorizontalSpacing: 10px
$buttonVerticalSpacing: 10px

.subcategories
	display: flex
	gap: 0.8em
	flex-wrap: wrap

	@media (min-width: common.$break992)
		gap: 1.5em

.wrapper
	display: flex
	justify-content: center
	align-content: center
	flex-wrap: wrap
	margin: (-$buttonVerticalSpacing) (-$buttonHorizontalSpacing)
	text-transform: lowercase
