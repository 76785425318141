@use 'common'

.wrapper
	background-color: common.$c-white

.section
	padding: 25px
	border-bottom: 1px solid common.$text-color3

	&In
		max-width: 700px
		margin: auto

	&:nth-child(2n)
		background-color: common.$c-grey-light4

	&:nth-last-child(1)
		border-bottom: initial

	&.is_fullSize &
		&In
			max-width: 100%

.contentAlignCenter
	text-align: center

.title
	text-align: center
	font-size: 28px
	color: common.$c-comp
	margin: 0

	strong, b
		color: common.$c-main

	@media (min-width: common.$break480)
		font-size: 40px
