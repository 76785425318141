@use 'common'

.items
	display: flex
	flex-wrap: wrap
	gap: .625rem

.is_align_center
	justify-content: center

.item
	display: flex
