@use 'common'

.wrapper
	//

.list
	--eshop-list-row-gap: 2rem

	display: grid
	grid-row-gap: var(--eshop-list-row-gap)

	@media (min-width: common.$break480)
		gap: 30px
		grid-template-columns: 1fr 1fr

	@media (min-width: common.$break768)
		grid-template-columns: 1fr 1fr 1fr
		--eshop-list-row-gap: 5rem

.item
	//
