
@use 'common'

.wrapper
	display: flex
	flex-direction: column
	justify-content: center
	align-items: center
	gap: .2em
	color: common.$c-grey-dark3

	@media (min-width: common.$break768)
		flex-direction: row
		align-items: initial
		justify-content: flex-start
		gap: .5em

.age
	//

.duration
	//

.time
	//

.place
	//

.verticalDivider
	width: 1px
	background-color: common.$c-grey-dark3
