@use "common"

$linkHorizontalSpace: 5px
$linkVerticalSpace: 5px

=link-hover-focus-style
	text-decoration: underline
	outline: none

.wrapper
	//

.list
	list-style: none
	display: flex
	margin: (-$linkVerticalSpace) (-$linkHorizontalSpace)

.item
	padding: $linkVerticalSpace $linkHorizontalSpace

.link
	display: block
	color: inherit
	padding: 10px
	font-size: 20px
	line-height: 2
	font-weight: 300
	text-decoration: none

	&:hover
		+link-hover-focus-style

	&:focus-visible
		+link-hover-focus-style
