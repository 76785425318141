@use 'common'

.wrapper
	width: 100%
	margin: 0 0 12px

	&.view_textArea
		.input
			min-height: 100px
			resize: vertical

	&.view_radio
		margin: 0

	&.view_checkbox, &.view_radio
		display: flex

		.input
			width: initial
			margin: 0
			margin-right: 10px
			flex-shrink: 0

		.checkboxProgramAndTicketPageNewsletterBox

			width: 20px
		.label
			display: flex

			&:hover
				cursor: pointer

.label
	//

	&Text

		&.is_big
			font-size: 18px
			font-weight: 700

.input
	font-family: common.$font-family-base
	background-color: common.$c-white
	border: 1px solid common.$c-grey-light3
	border-radius: 3px
	padding: 12px 10px
	width: 100%
	font-size: 1em
	transition: outline .3s
	outline: none
	line-height: 1.6

	&:focus-visible
		outline: 2px solid common.$c-grey-dark2
