@use 'common'

.wrapper
	margin-top: 15px
	padding: 0 var(--page-horizontal-spacing) common.$siteContentPaddingBottom

.content
	max-width: 600px
	margin: 25px auto
	text-align: center
	font-size: 17px
	line-height: 1.4

	@media (min-width: 768px)
		margin-top: 50px
		margin-bottom: 50px

.switcher
	display: flex
	justify-content: center
	flex-wrap: wrap
	gap: 20px

.buttonSwitch
	display: flex

.main

	&.view_employees
		padding-top: 50px
