@use 'common'

.dividerWrapper
	margin: 2em 0

.title
	font-size: 3em
	margin-bottom: 0.4em

	@media (min-width: common.$break992)
		font-size: 4em

.description
	color: #{common.$c-grey-dark2}
	font-size: 1.1em

	@media (min-width: common.$break992)
		font-size: 1.3em

.workshopList
	margin-top: 2em
