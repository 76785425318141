@use 'common'

.wrapper
	height: calc((var(--LogoItem-image-size, ) * 1px) * (0.5 + var(--LogoItem-image-volume)) / 1.5)
	aspect-ratio: var(--LogoItem-image-aspectRatio)
	display: grid

	@media (min-width: common.$break768)
		flex-shrink: 0

.link
	display: contents

.image
	width: 100%
	height: 100%
