@use 'common'
@use 'sass:math'

$tileGap: 10px

.wrapper
	//

.tiles

	.view_grid
		display: grid
		margin: (-$tileGap)

		@media (min-width: common.$break480)
			grid-template-columns: 1fr 1fr

		@media (min-width: common.$break992)
			grid-template-columns: 1fr 1fr 1fr

.group

	&:not(:first-child)
		padding-top: 20px

.tile

	&.view_medium, &.view_large, &.view_small
		background-color: common.$c-grey
		grid-column: span 1
		margin: $tileGap

		.title
			font-size: 18px

	&.view_medium, &.view_large

		@media (min-width: common.$break480)
			grid-column: span 2

	&.view_large

		@media (min-width: common.$break992)
			grid-column: span 3

			.title
				font-size: 26px

	&.view_full
		//
