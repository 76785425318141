@use "common"

$itemHorizontalSpacing: 5px
$itemVerticalSpacing: 5px
$linkHorizontalSpacing: $itemHorizontalSpacing
$linkVerticalSpacing: $itemVerticalSpacing
$newsletterBoxWidth: 450px

.wrapper
	//

.list
	position: relative
	display: flex
	justify-content: center
	align-items: center
	margin: (-$itemVerticalSpacing) (-$itemHorizontalSpacing)
	list-style: none

	@media (min-width: common.$break992)
		justify-content: flex-end

.item
	width: 50px + $itemVerticalSpacing + $itemHorizontalSpacing
	padding: $itemVerticalSpacing $itemHorizontalSpacing

.button
	position: relative
	width: 100%
	height: 100%
	background-color: initial
	border: initial
	cursor: pointer

.link, .button
	display: block
	padding: $linkHorizontalSpacing $linkVerticalSpacing
	outline: none

.newsletter
	max-width: $newsletterBoxWidth
	width: calc(100vw - (2 * var(--page-horizontal-spacing)))
	position: absolute
	bottom: 100%
	left: 50%
	transform: translateX(-50%)
	margin: 0
	padding: var(--page-horizontal-spacing)
	transition-property: visiblity opacity
	transition-duration: .2s
	visibility: hidden
	opacity: 0

	&.is_visible
		visibility: initial
		opacity: 1

	@media (min-width: common.$break992)
		right: calc(100% + 15px)
		bottom: 0
		left: auto
		transform: initial

	&Close
		position: absolute
		top: 25px
		right: 25px
		width: 32px
		height: 32px
		background-color: transparent
		border: initial
		cursor: pointer
		transition: transform .2s
		z-index: 1

		&:hover, &:focus
			transform: rotate(90deg)

		&::before, &::after
			content: ''
			position: absolute
			width: 100%
			height: 1px
			top: 50%
			right: 0
			background-color: common.$c-black

		&::before
			transform: rotate(45deg)

		&::after
			transform: rotate(-45deg)
