@use "common"

.wrapper
	position: absolute
	top: var(--page-header-height)
	left: 0
	right: 0
	z-index: 10
	transform: translateY(calc(-1 * (100% + 78px)))
	background-color: common.$text-color
	overflow: auto
	transition: transform .4s

	&.is_open
		transform: translateY(0)

	@media (min-width: common.$break992)
		max-height: auto
		width: var(--HeaderMainNavigationSidebar-desktop-width, 250px)
		position: fixed
		left: 100%
		top: 0
		bottom: 0
		transition: none

		&.is_open
			max-height: auto

.navigation
	$navigationVerticalSpacing: 25px

	padding-bottom: $navigationVerticalSpacing

	@media (min-width: common.$break992)
		padding-top: $navigationVerticalSpacing

.list
	list-style: none

.item
	border-bottom: 1px solid common.$c-border

.link
	position: relative
	display: block
	padding: 10px 25px
	width: 100%
	font-size: 1.3em
	font-weight: 300
	text-decoration: none
	color: common.$c-white
	transition: background-color .2s
	outline: none

	&:before
		content: ''
		position: absolute
		left: 20px
		top: 50%
		margin-top: -2px
		visibility: hidden
		opacity: 0
		transition: all .2s

		// arrow styles
		border-top: 3px solid
		border-left: 3px solid
		height: 10px
		width: 10px
		transform: rotate(-45deg) translateY(-50%)

	&:hover, &:focus, &.isActiveOnHover
		background-color: common.$c-menu-button-hover

	@media (min-width: common.$break992)
		&.isActiveOnHover
			&:before
				visibility: initial
				opacity: 1

	@media (min-width: common.$break992)
		font-size: 1.2em
		padding: 8px 38px

.languages
	padding-bottom: 25px
