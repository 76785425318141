@use 'common'

$borderRadius: var(--border-radius)
$filterVerticalSpacing: .5em
$filterHorizontalSpacing: .5em

.wrapper
	//

.main
	--program-and-ticket-page-horizontal-spacing: var(--page-horizontal-spacing)

	max-width: calc(1300px + 2 * var(--program-and-ticket-page-horizontal-spacing))
	width: 100%
	margin: auto
	padding: 0 var(--program-and-ticket-page-horizontal-spacing) common.$siteContentPaddingBottom

.infoBox
	display: flex
	flex-direction: column
	gap: 2em
	padding: 3em 0
	text-align: left

	@media (min-width: common.$break768)
		flex-direction: row

.titleBox

	@media (min-width: common.$break768)
		flex-basis: 60%
		padding-right: 2em

.title
	font-size: 3em
	text-align: center

.switcher
	display: flex
	justify-content: center
	flex-wrap: wrap
	white-space: nowrap

	&Button
		font-size: 16px
		overflow: hidden

		&:first-child
			border-top-left-radius: $borderRadius
			border-bottom-left-radius: $borderRadius

		&:last-child
			border-top-right-radius: $borderRadius
			border-bottom-right-radius: $borderRadius

.newsletter
	width: 100%
	height: fit-content
	margin-top: 1em
	display: flex
	flex-direction: column
	gap: 20px
	justify-content: flex-start

	@media (min-width: common.$break768)
		width: 50%

.newsletterBox
	//

.sideContent
	padding: 2em 0
	flex-basis: 40%

.filters
	margin-top: 1em

	&In
		display: flex
		flex-wrap: wrap
		margin: (-$filterVerticalSpacing) (-$filterHorizontalSpacing)

.filter
	padding: $filterVerticalSpacing $filterHorizontalSpacing

	.is_mobile
		@media (min-width: common.$break768)
			display: none

	.is_desktop
		display: none

		@media (min-width: common.$break768)
			display: initial

	&.view_months

		@media (min-width: common.$break768)
			flex-basis: 100%

.program
	margin-top: 1em
