@use 'common'

$itemHorizontalSpacing: 5px
$itemVerticalSpacing: 5px

.wrapper
	background-color: common.$c-grey-dark3

.main

	@media (min-width: common.$break992)
		display: grid
		grid-template-columns: auto auto
		justify-content: center
		gap: 20px

.columnSelect

	@media (min-width: common.$break992)
		display: grid
		grid-template-columns: auto 1fr
		align-items: center
		gap: 20px

.title
	font-size: 18px
	color: common.$text-color3
	text-align: center

	@media (min-width: common.$break992)
		font-size: 20px
		margin: 0

.select
	max-width: 400px
	margin: 0 auto 10px

	@media (min-width: common.$break992)
		width: 220px
		margin: auto

	:global
		.select__control
			display: flex
			overflow: hidden
			border: 1px solid common.$c-white
			background-color: transparent
			cursor: pointer
			caret-color: common.$c-white

			&:hover, &--is-focused
				cursor: pointer
				border: 1px solid common.$c-grey
				box-shadow: unset

			&--is-focused
				.select__placeholder
					opacity: .4

		.select__placeholder
			font-size: 18px
			color: common.$c-white

		.select__input-container
			color: common.$c-white

		.select__menu-list
			max-height: 200px

.list
	display: flex
	justify-content: center
	flex-wrap: wrap

	margin: (-$itemVerticalSpacing) (-$itemHorizontalSpacing)
	list-style: none

.item
	display: flex
	padding: $itemVerticalSpacing $itemHorizontalSpacing

.button
	cursor: pointer
	background-color: transparent
	color: common.$c-white
	border: none
	text-decoration: underline
	outline: none

	&:hover, &:focus, &.is_active
		text-decoration: none

	&.is_active
		font-weight: 700
