@use 'common'

.wrapper
	display: flex
	flex-direction: column
	gap: 40px

	@media (min-width: common.$break992)
		flex-direction: row

.close
	position: absolute
	top: 10px
	right: 10px
	width: 32px
	height: 32px
	background-color: initial
	border: initial
	padding: 0
	transition: transform .2s
	cursor: pointer
	outline: unset

	&:hover, &:focus
		transform: rotate(90deg)

	&::before, &::after
		content: ""
		display: block
		width: 100%
		height: 1px
		background-color: common.$c-black

	&::before
		transform: rotate(45deg)

	&::after
		transform: rotate(-45deg)

.gallery
	//

.content
	//

.name
	font-size: 28px
	text-align: center
	padding-left: 25px
	padding-right: 25px
	padding-bottom: 10px
	border-bottom: 1px solid common.$c-grey-light3

	@media (min-width: common.$break992)
		text-align: initial
		padding-left: 0
		font-size: 50px
