@use 'common'

.wrapper
	position: sticky
	z-index: 10
	left: 0
	right: 0
	bottom: 0
	padding: 1em
	background-color: #ffffff
	text-align: center
	display: flex
	justify-content: space-around

.link
	margin: 30px
	padding: 30px
	font-size: 60px
	background: white
	color: #E74C3C
	box-shadow: 0 0px 20px 3px #E74C3C
	border-radius: 12px
	text-decoration: none
	display: inline-block
	box-sizing: border-box
	text-align: center
