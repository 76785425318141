@use 'common'
@use 'sass:math'

=image-hover-focus-style
	transform: translateY(-10px)

.wrapper
	//

	&.view_personTile

		.images
			justify-content: center

			@media (min-width: common.$break992)
				display: grid
				grid-template-columns: 1fr 1fr

.images
	display: flex
	flex-wrap: wrap
	gap: 15px

.button
	$pictureWidth: 100
	$aspectRatio: math.div($pictureWidth, 75)

	position: relative
	width: $pictureWidth * 1px
	border: unset
	cursor: pointer
	padding: 0
	transition: transform .2s
	outline: unset

	&::before
		content: ''
		display: block
		padding-top: math.div(100%, $aspectRatio)

	&:hover
		+image-hover-focus-style

	&:focus-visible
		+image-hover-focus-style

.image
	object-fit: cover
