@use 'common'

.wrapper
	display: flex
	flex-direction: column

.button
	margin: 0 auto 20px
	max-width: 280px
	border-radius: 8px
	overflow: hidden
	width: 100%
	height: auto
	border: none
	padding: 0
	cursor: pointer

.image
	width: 100%
	height: auto

.name
	margin: 0
	margin-bottom: 5px
	font-size: 1.2em
	line-height: 1.2
	font-weight: 600
	text-align: center

.description
	margin-bottom: 5px

.price
	text-align: center
