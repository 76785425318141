@use 'common'

.wrapper
	//

.select
	width: 100%
	margin: 0 auto 10px

	@media (min-width: common.$break992)
		margin: auto

	:global
		.select__control
			display: flex
			overflow: hidden
			border: 1px solid common.$c-white
			background-color: common.$c-white
			cursor: pointer
			caret-color: inherit

			&:hover, &--is-focused
				cursor: pointer
				border: 1px solid common.$c-grey
				box-shadow: unset

			&--is-focused
				.select__placeholder
					opacity: .4

		.select__placeholder
			font-size: 18px
			color: common.$c-black

		.select__input-container
			color: common.$c-black

		.select__option
			z-index: 0

.link
	display: flex
	flex-direction: column
	color: inherit
	font-family: inherit
	text-decoration: none
	// @TODO: remove padding on parent
	// padding-block: 10px
	z-index: 0
