@use "sass:math"
@use "common"

=hover-focus-styles
	&.view_hover
		transform: translateY(-10px)

.wrapper
	$aspectRatio: math.div(1, 1)

	position: relative
	width: 100%
	margin: auto
	background-color: common.$c-grey-light2
	border-radius: 50%
	overflow: hidden
	transition: transform .2s

	&::before
		content: ''
		display: block
		padding-top: math.div(100%, $aspectRatio)

	// styles for hover & focus-visible are separated because safari currently doesn't support focus-visible
	button:hover &, a:hover &
		+hover-focus-styles

	button:focus-visible &, a:focus-visible &
		+hover-focus-styles

.image
	object-fit: cover
