@use 'common'

.wrapper
	--Content-sectionInBetweenSpacing: 40px

	@media (min-width: common.$break992)
		--Content-sectionInBetweenSpacing: 80px

	.section

		&:not(:last-child)
			margin-bottom: var(--Content-sectionInBetweenSpacing)

		&.view_tiles
			//

		&.view_fileOrLink
			//

		&.view_gallery
			margin-right: auto
			margin-left: auto

			.container
				padding: 25px 0
				border-block: 1px solid common.$c-grey-light2

			&.view_fourTiles
				max-width: 800px

			&.view_full
				max-width: initial

		&.view_partner

			&Item
				text-align: center

		&.view_partnerList
			//

		&.view_youtubeVideos
			//

		&.view_anchor
			margin: 0
			height: 0
			width: 0

			.isNonVisible
				visibility: none
				opacity: 0

	// Contember content editor
	[data-contember-level="1"]
		font-size: 24px
		padding: 38px 0
		text-align: center
		margin: 0

		@media (min-width: common.$break768)
			font-size: 50px

	[data-contember-type="table"]
		margin: 0 auto
		border: 1px solid common.$c-grey-light7
		border-spacing: 0
		border-collapse: collapse
		margin-bottom: var(--Content-sectionInBetweenSpacing)
		max-width: 500px

	[data-contember-type="tableRow"]

		&:nth-child(even)
			background-color: common.$c-white

	[data-contember-type="tableCell"]
		padding: 5px 20px
		border-bottom: 1px solid common.$c-grey-light7

	[data-contember-type="paragraph"]
		margin-bottom: 20px

	[data-contember-type="anchor"]
		&:hover, &:focus
			outline: initial
			text-decoration: none

	[data-contember-justify="center"],
	[data-contember-align="center"]
		text-align: center

	[data-contember-justify="end"],
	[data-contember-align="end"]
		text-align: end
