@use 'common'

=inline-filters
	display: flex
	justify-content: center
	flex-wrap: wrap
	gap: 2px

.wrapper
	+inline-filters
