@use 'common'

.wrapper
	background-color: common.$c-white

	&:not(:last-child)
		margin-bottom: 25px

.image
	display: none

	&In
		object-fit: cover

	@media (min-width: 768px)
		background-color: common.$c-grey
		position: relative
		display: block
		height: 400px

.content
	padding: 40px 30px
	text-align: center

.header
	margin-bottom: 25px

.title
	color: common.$c-black
	font-size: 24px
	margin: unset

	&Link
		color: inherit
		text-decoration: none

		&:hover, &:focus-visible
			text-decoration: underline

	@media (min-width: 768px)
		font-size: 50px

.tags
	margin-top: .625rem

.lead
	font-size: 18px
	margin-bottom: 25px

	@media (min-width: 768px)
		font-size: 20px

.button
	display: inline-block
