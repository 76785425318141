@use 'common'

.wrapper
	//

.title
	max-width: 460px
	margin-inline: auto

.list
	max-width: 670px
	margin-inline: auto

.item
	&:not(:first-child)
		margin-top: 50px

.button
	display: flex
	justify-content: center
	margin-top: 50px
	text-transform: uppercase
	font-size: 19px

	&In
		display: flex
		justify-content: center
		align-items: center
		gap: 10px
