@use 'common'

.wrapper
	//

.link
	text-decoration: none

	&:hover
		.image
			img
				transform: scale(1.02)

.figure
	margin: initial

.image
	overflow: hidden
	$aspectRatio: var(--ImageBlock-image-width) / var(--ImageBlock-image-height)
	position: relative
	aspect-ratio: $aspectRatio
	width: 100%
	background-color: common.$c-grey-light5
	text-align: center
	z-index: 0

	&In
		object-fit: cover
		transition: transform .8s

.caption
	border-top: 2px solid common.$c-black
	color: common.$c-black
	font-size: 18px
	padding-top: 10px
	margin-top: 15px
