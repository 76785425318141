@use 'common'

.wrapper
	//

.header
	margin-bottom: 25px

.gallery
	margin-bottom: 20px
