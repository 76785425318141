@use 'common'

$dotWidth: 1em
$dotHeight: 1em
$dotSpacing: 5px
$originPosition: 9999px

.wrapper
	position: absolute
	inset: 0
	background-color: rgba(0, 0, 0, .3)
	z-index: 40
	pointer-events: all
	cursor: not-allowed

.main
	position: absolute
	left: 50%
	top: 50%
	transform: translate(-50%, -50%)

.dot
	position: relative
	left: (-$originPosition)
	width: var(--LoadingDots-size)
	height: var(--LoadingDots-size)
	border-radius: 50%
	background-color: common.$c-main
	color: common.$c-main
	box-shadow: $originPosition 0 0 calc(-1 * var(--LoadingDots-size)) common.$c-main
	animation: dotPulse var(--LoadingDots-animationDuration) infinite linear
	animation-delay: .25s

	&::before, &::after
		content: ''
		display: inline-block
		position: absolute
		top: 0
		width: var(--LoadingDots-size)
		height: var(--LoadingDots-size)
		border-radius: 50%
		background-color: common.$c-main
		color: common.$c-main

	&::before
		box-shadow: calc($originPosition - var(--LoadingDots-size)) 0 0 calc(-1 * var(--LoadingDots-size)) common.$c-main
		animation: dotPulseBefore var(--LoadingDots-animationDuration) infinite linear
		animation-delay: 0s

	&::after
		box-shadow: calc($originPosition + var(--LoadingDots-size)) 0 0 calc(-1 * var(--LoadingDots-size)) common.$c-main
		animation: dotPulseAfter var(--LoadingDots-animationDuration) infinite linear
		animation-delay: .5s

@keyframes dotPulseBefore
	0%
		box-shadow: calc($originPosition - var(--LoadingDots-size) - $dotSpacing) 0 0 calc(-1 * var(--LoadingDots-size)) common.$c-main

	30%
		box-shadow: calc($originPosition - var(--LoadingDots-size) - $dotSpacing) 0 0 2px common.$c-main

	60%,
	100%
		box-shadow: calc($originPosition - var(--LoadingDots-size) - $dotSpacing) 0 0 calc(-1 * var(--LoadingDots-size)) common.$c-main

@keyframes dotPulse
	0%
		box-shadow: $originPosition 0 0 calc(-1 * var(--LoadingDots-size)) common.$c-main

	30%
		box-shadow: $originPosition 0 0 2px common.$c-main

	60%,
	100%
		box-shadow: $originPosition 0 0 calc(-1 * var(--LoadingDots-size)) common.$c-main

@keyframes dotPulseAfter
	0%
		box-shadow: calc($originPosition + var(--LoadingDots-size) + $dotSpacing) 0 0 calc(-1 * var(--LoadingDots-size)) common.$c-main

	30%
		box-shadow: calc($originPosition + var(--LoadingDots-size) + $dotSpacing) 0 0 2px common.$c-main

	60%,
	100%
		box-shadow: calc($originPosition + var(--LoadingDots-size) + $dotSpacing) 0 0 calc(-1 * var(--LoadingDots-size)) common.$c-main
