@use 'common'
@use 'sass:math'

=hover-focus-style
	transform: scale(1.05)

.wrapper
	//

	&.view_fourTiles

		.images
			display: grid
			grid-template-columns: 50% 50%
			gap: 1px

		.button
			$aspectRatio: math.div(400px, 270px)

			&::before
				padding-top: math.div(100%, $aspectRatio)

	&.view_full
		.images
			display: flex
			flex-wrap: wrap
			gap: 10px

		.button
			$aspectRatio: calc(var(--GridGallery-image-width) / var(--GridGallery-image-height))

			height: 150px
			max-width: calc(150px * $aspectRatio)
			width: 100%

			&::before
				padding-top: calc(100% / $aspectRatio)

.button
	position: relative
	padding: 0
	border: unset
	cursor: pointer
	transition: transform .2s
	outline: unset

	&::before
		content: ''
		display: block

	&:hover
		+hover-focus-style

	&:focus-visible
		+hover-focus-style

.image
	object-fit: cover
