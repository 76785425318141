@use 'common'

.wrapper

	&:not(:last-child)
		margin-bottom: 25px

.image
	display: block
	position: relative
	display: block
	height: 13rem

	&In
		object-fit: cover

	@media (min-width: common.$break768)
		height: 400px

.content
	padding: 10px 0

	@media (min-width: common.$break992)
		padding: 2rem 0

.header
	margin-bottom: 20px

.title
	color: common.$c-black
	font-size: 24px
	margin: unset

	&Link
		color: inherit
		text-decoration: none

		&:hover, &:focus-visible
			text-decoration: underline

	@media (min-width: 768px)
		font-size: 30px

.description
	display: flex
	justify-content: flex-start
	align-items: center
	gap: 10px

.divider
	background-color: common.$c-black
	width: 1px
	height: 20px

.tags
	//

.lead
	font-size: 1em

	@media (min-width: 768px)
		font-size: 1.2em
