@use "common"

.wrapper
	//

.main
	margin-top: 80px
	padding-bottom: common.$siteContentPaddingBottom

	@media (min-width: common.$break992)
		display: flex
		justify-content: space-between

.column

	&Main
		@media (min-width: common.$break992)
			width: 55%

	&Side
		@media (min-width: common.$break992)
			width: 40%

.contact
	margin: 0 auto
	max-width: 400px
	width: 100%

	&Title
		padding: 25px 0

	@media (min-width: common.$break992)
		max-width: 100%
		margin-right: 0

		&Title
			padding-top: 0

	&Map
		height: 400px
		margin-top: 25px

		&Iframe
			border: initial
