@use 'common'

.body
	overflow: hidden

.modal
	$modalMaxWidth: var(--content-width-normal)
	$modalHorizontalSpacing: var(--page-horizontal-spacing)
	$modalVerticalSpacing: var(--page-vertical-spacing)

	max-width: calc(#{$modalMaxWidth} + 2 * #{$modalHorizontalSpacing})
	width: 100%
	margin-left: auto
	margin-right: auto
	padding: $modalVerticalSpacing $modalHorizontalSpacing

	margin: auto
	position: absolute
	display: flex
	top: 20px
	right: 0
	left: 0
	bottom: auto

	&:focus-visible
		outline: none

	&In
		position: relative
		width: 100%
		padding: $modalHorizontalSpacing $modalVerticalSpacing
		border-radius: 4px
		overflow: hidden
		background-color: common.$c-white

		&.no_vertical_padding
			padding: 0

	&Close
		width: 44px
		height: 44px
		line-height: 44px
		font-size: 28px
		position: absolute
		top: 0
		right: 0
		background: transparent
		border: none
		cursor: pointer
		color: common.$c-grey-dark3
		opacity: .65
		transition: opacity .2s

		&:hover, &:focus
			opacity: 1
			outline: none

.overlay
	position: fixed
	inset: 0
	background-color: rgba(0, 0, 0, .6)
	z-index: 100
	visibility: hidden
	opacity: 0
	transition-property: visibility, opacity
	transition-duration: .2s

	&.is_open
		visibility: initial
		opacity: 1
		overflow: auto

	&.before_close
		visibility: hidden
		opacity: 0
