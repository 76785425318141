
@use 'common'

.wrapper
	display: flex

	.view_uppercase
		text-transform: uppercase

.button

	&Content
		line-height: 1.1
		// original values
		// font-size: 19px
		// line-height: 1.3

		// @media (min-width: common.$break768)
			// font-size: 24px
