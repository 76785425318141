@use 'common'

.wrapper
	//

.main

	@media (min-width: common.$break768)
		display: flex
		gap: 20px

.description
	text-align: left

	@media (min-width: common.$break768)
		width: 40%

.image
	margin-top: 20px

	&In
		width: 100%
		height: auto

	@media (min-width: common.$break768)
		margin-top: 0
		width: 60%
