@use 'common'
@use './ProgramAndTicketPageFilterVenues.module' as filterVenues

.wrapper
	+filterVenues.inline-filters

.button
	display: none

	&.is_inView
		display: initial
