@use 'common'

$buttonHorizontalSpacing: 10px
$buttonVerticalSpacing: 10px

.wrapper
	display: flex
	justify-content: center
	align-content: center
	flex-wrap: wrap
	margin: (-$buttonVerticalSpacing) (-$buttonHorizontalSpacing)

.button
	display: flex
	flex-direction: column
	margin: $buttonVerticalSpacing $buttonHorizontalSpacing

.description
	background-color: common.$c-blue
	margin-top: -2px

	&List
		list-style: none
		padding: 0 1em 1em

	&Item
		display: flex

	&Checkmark
		position: relative
		display: inline-block
		width: 22px
		height: 22px
		transform: rotate(45deg)
		flex-shrink: 0

		&::before
			content: ''
			position: absolute
			width: 3px
			height: 15px
			background-color: #ccc
			left: 12px
			top: 5px

		&::after
			content: ''
			position: absolute
			width: 10px
			height: 3px
			background-color: #ccc
			left: 5px
			top: 20px

	&Text
		color: common.$c-white
		font-size: 1.125em
		padding-left: 5px

		[data-contember-type="anchor"]
			color: common.$c-white

			&:hover, &:focus
				text-decoration: none
