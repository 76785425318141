@use "common"
@use "sass:math"

$itemVerticalSpacing: 5px
$itemHorizontalSpacing: 10px

.wrapper
	//

.list
	display: flex
	align-items: center
	flex-wrap: wrap
	margin: (-$itemVerticalSpacing) (-$itemHorizontalSpacing)

.item
	padding: $itemVerticalSpacing $itemHorizontalSpacing

.link
	outline: none
