@use 'common'

.wrapper
	--ProgressLoadingIndicator-color: #{common.$c-main}

	background: var(--page-background-color, #{common.$c-bg})
	display: grid
	grid-template-rows: auto 1fr auto // 1.header, 2.main content, 3.footer
	min-height: 100vh

	&.is_dotykovaObrazovka
		-webkit-tap-highlight-color: transparent

		*::selection
			color: none
			background: none

	&.view_stesticka
		background: common.$c-white

	&.view_programAndTicket
		background: common.$c-grey-light8

	&.view_workshopPage
		background: common.$c-white

	&.view_newspaperPage
		background: url(/minoroviny/bg-newspaper.jpg) top center
		background-attachment: fixed

.cover
	//

.main
	position: relative
