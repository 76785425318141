@use 'common'

.wrapper
	position: relative
	z-index: 0

.carousel
	--DetailHero-medium-width: 10
	--DetailHero-medium-height: 7
	+common.hideHorizontalScrollbar

	position: relative
	scroll-snap-type: x mandatory
	display: flex
	align-items: center
	z-index: 0
	aspect-ratio: calc(var(--DetailHero-medium-width) / var(--DetailHero-medium-height))

	@media (max-aspect-ratio: 16/8) and (max-width: common.$break1600 - 1px)
		--DetailHero-medium-width: 960
		--DetailHero-medium-height: 747

.slide
	min-width: 100%
	height: 100%
	flex-shrink: 0
	scroll-snap-align: start

.medium
	padding: unset
	border: unset
	cursor: pointer

	width: 100%
	height: 100%
	position: relative
	background-color: common.$c-grey-light3

	img
		object-fit: contain

.playButton
	position: absolute
	inset: 0
	display: flex
	justify-content: center
	align-items: center
	z-index: 1
	font-size: 4em
	color: white
	transition: color .2s

	&:hover, &:focus-visible
		color: common.$c-grey-dark2

.navigation
	position: absolute
	top: 50%
	right: 0
	left: 0
	transform: translateY(-50%)
	z-index: 1
	pointer-events: none
