@use 'common'

.wrapper
	display: flex
	flex-direction: column
	align-content: center
	text-align: center

.header
	margin-bottom: 25px

.telephone, .email
	//

.link
	outline: none
	display: inline-block

	&:hover, &:focus
		text-decoration: none
