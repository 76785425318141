@use 'common'

.wrapper
	--Error404Page-elementBetweenBottomSpacing: 20px

	@media (min-width: common.$break768)
		--Error404Page-elementBetweenBottomSpacing: 40px

.main
	padding: 100px 0

	@media (min-width: common.$break992)
		display: flex
		justify-content: center
		align-content: center
		gap: 60px

.image
	display: none

	@media (min-width: common.$break992)
		display: block
		flex-shrink: 0

.content
	display: flex
	justify-content: center
	align-items: center
	flex-direction: column

	@media (min-width: common.$break992)
		align-items: flex-start

.title
	font-size: 60px
	margin-bottom: var(--Error404Page-elementBetweenBottomSpacing)

	@media (min-width: common.$break768)
		font-size: 200px

.text
	margin-bottom: var(--Error404Page-elementBetweenBottomSpacing)

	@media (min-width: common.$break768)
		font-size: 22px

.button
	display: inline-block
	font-size: 12px
