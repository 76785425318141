@use 'common'

.wrapper
	margin: 25px
	display: flex
	flex-direction: column

	@media (min-width: common.$break992)
		flex-direction: row
		gap: 6rem

.switcher
	$switcherBottomSpacing: calc(2 * var(--page-vertical-spacing))

	margin-bottom: $switcherBottomSpacing

	@media (min-width: common.$break992)
		width: 300px

.description
	margin-top: -2px
	display: flex
	flex-direction: column
	gap: 3px

	&List
		list-style: none
		padding: 0 1em 1em

	&Item
		display: flex

	&Checkmark
		position: relative
		display: inline-block
		width: 22px
		height: 22px
		transform: rotate(45deg)
		flex-shrink: 0

		&::before
			content: ''
			position: absolute
			width: 3px
			height: 15px
			left: 12px
			top: 5px

		&::after
			content: ''
			position: absolute
			width: 10px
			height: 3px
			left: 5px
			top: 20px

	&Text
		font-size: 1em

		&:hover, &:focus
			text-decoration: none

		@media (min-width: common.$break992)
			font-size: 0.9em

.switcherWithSubcategories
	margin-bottom: 2.5em
