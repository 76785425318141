@use 'common'

.wrapper

	&.is_page
		font-size: 20px

	&.is_block

		.subInfo
			font-size: 16px

	@media (min-width: common.$break768)
		display: flex
		justify-content: space-between
		align-items: center

.subInfo
	//

.actions
	display: flex
	justify-content: center
