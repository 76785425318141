@use "common"

=link-hover-focus-style
	text-decoration: none

.wrapper
	//

.list
	list-style: none

.item
	margin-top: 7px

.link
	font-size: 18px
	font-weight: 300
	padding: 5px
	color: common.$c-white
	outline: none

	&:hover
		+link-hover-focus-style

	&:focus-visible
		+link-hover-focus-style
