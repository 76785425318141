@use 'common'

.wrapper
	max-width: 800px
	margin: auto

.filters
	display: flex
	justify-content: center
	flex-wrap: wrap
	gap: 25px
	text-align: center

	@media (min-width: common.$break768)
		justify-content: flex-start
		text-align: left
		gap: 50px

.filter

	&Label
		font-size: 18px
		font-weight: 400

	&Buttons
		font-size: .75em

		@media (min-width: common.$break768)
			font-size: .9em

.prices
	width: 100%
	text-align: center

.description
	margin-top: 25px
	text-align: center
