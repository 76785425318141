@use 'common'

.wrapper
	background-color: common.$c-white

	&.is_dotykovaObrazovka
		pointer-events: none

.hero
	//

.content
	margin-top: 40px

	@media (min-width: common.$break992)
		margin-top: 80px

.reviews
	margin-top: 60px
	padding-block: 32px
	background-color: common.$c-grey-light9

	&In

		@media (min-width: common.$break768)
			display: flex
			justify-content: center

.column
	$verticalSpacing: 32px
	display: flex
	justify-content: center
	margin-bottom: $verticalSpacing

	@media not all and (min-width: common.$break768)
		&:nth-child(2)
			padding-top: $verticalSpacing
			border-top: 1px solid common.$c-grey-light5

	@media (min-width: common.$break768)
		margin-bottom: 50px
		padding-inline: 32px

		&:nth-child(2)
			border-left: 1px solid common.$c-grey-light5

.review
	&Footer
		margin-top: 15px

	&Link
		font-weight: 600

		&:hover, &:focus-visible
			text-decoration: none

	&Actions
		font-size: 19px
		display: flex
		justify-content: center
