@use 'sass:math'

=normalizeSize($intrinsicWidth, $intrinsicHeight)
	width: math.div($intrinsicWidth, $intrinsicHeight) * 1em
	height: 1em

.wrapper
	//

.icon
	color: inherit

	&-arrowDown, &-arrowUp, &-arrowRight
		+normalizeSize(24, 24)

	&-caret
		+normalizeSize(10, 16)

	&-play
		+normalizeSize(47, 47)

	&-mangoHeart
		+normalizeSize(16, 15)

	&-theatre
		+normalizeSize(62, 62)

	&-calendar
		+normalizeSize(23, 23)

	&-cancel
		+normalizeSize(24, 24)
