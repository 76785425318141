@use 'common'

$itemHorizontalSpacing: 10px
$itemVerticalSpacing: 10px

.wrapper
	//

.list
	display: flex
	justify-content: center
	align-content: center
	flex-wrap: wrap
	margin: (-$itemVerticalSpacing) (-$itemHorizontalSpacing)

.item
	padding: $itemVerticalSpacing $itemHorizontalSpacing
