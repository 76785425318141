@use 'common'

$elementSpacing: 14px

.wrapper
	width: 100%
	padding: $elementSpacing
	background-color: common.$c-white
	box-shadow: 0 0 5px rgba(0 0 0 / 10%), 0 0 40px rgba(0 0 0 / 10%)
	border-radius: .625rem

	@media (min-width: common.$break480)
		padding-inline: 24px

.title
	font-weight: 400
	color: common.$c-grey
	padding-bottom: $elementSpacing
	margin: unset
	border-bottom: 1px solid common.$c-grey-light6

	@media (min-width: common.$break768)
		font-size: 20px

.list
	//

.item

	&:not(:first-child)
		margin-top: $elementSpacing
		border-top: 1px solid common.$c-grey-light6

.emptyProgram
	text-align: center
	font-size: 17px
	opacity: .5

.item, .emptyProgram
	padding-top: 12px
