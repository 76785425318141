@use 'common'

.title
	font-size: 24px
	padding: 25px 0
	margin-bottom: 25px
	color: common.$text-color
	// text-align: center

	@media (min-width: common.$break480)
		font-size: 32px

	@media (min-width: common.$break768)
		font-size: 50px

	&.is_line
		position: relative
		border-bottom: 1px solid #cdcdcd
		z-index: 0

		&::after
			content: ''
			position: absolute
			height: 3px
			width: 80px
			left: 50%
			bottom: 0
			transform: translateX(-50%)
			background-color: common.$c-comp

	&.view_noVerticalPadding
		padding-top: 0
		padding-bottom: 0

	&.align_left
		text-align: left
	&.align_right
		text-align: right
	&.align_center
		text-align: center
