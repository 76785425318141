@use 'common'

$boxGap: 20px

.wrapper
	font-family: Georgia, serif

.title
	margin: 5px 0
	text-transform: uppercase
	text-align: center
	font-family: inherit
	font-size: 36px

	&Label
		font-size: 16px
		letter-spacing: -0.5px

	@media (min-width: common.$break768)
		font-size: 97px

	@media (min-width: common.$break992)
		font-size: 125px

.head

	&Top, &Bottom
		display: flex
		justify-content: space-between
		gap: 20px

	&Top
		.titleLabel
			&:first-child
				@media not all and (min-width: common.$break992)
					display: none

	&Bottom
		font-size: 14px
		margin-top: 0
		border-top: 3px solid
		border-bottom: 3px solid

		&In
			width: 100%
			margin: 1px 0
			border-top: 1px solid
			border-bottom: 1px solid
			text-align: center

			display: flex
			justify-content: space-between
			gap: 20px

			@media not all and (min-width: common.$break992)
				justify-content: center

		.titleLabel
			&:first-child, &:last-child
				@media not all and (min-width: common.$break992)
					display: none

		@media not all and (min-width: common.$break992)
			justify-content: center

.text
	margin: 40px auto
	max-width: 500px
	text-align: center
	font-size: 16px

.main
	//

.border

	&.is_double
		height: 3px
		border-top: 1px solid
		border-bottom: 1px solid

.boxes
	@media (min-width: common.$break992)
		margin-inline: -1%

.box
	$box: &
	padding: 5px
	border: 2px solid common.$text-color
	width: 100%
	margin-inline: auto

	@media not all and (min-width: common.$break992)
		max-width: 480px

	@media (min-width: common.$break992)
		float: left

		&:nth-child(2n)
			float: right

	&In
		padding: 20px

		&.is_bordered
			border: 1px solid common.$text-color

	&:not(:first-child)
		margin-block: $boxGap

	&:not(.is_filter)
		@media (min-width: common.$break992)
			max-width: 48%
			margin: 1%

	&.is_filter
		grid-column: -1 / 1
		margin-bottom: $boxGap

		@media (min-width: common.$break992)
			margin-bottom: 1%

		.select
			width: 100%

		#{$box}In
			@media (min-width: common.$break992)
				display: flex
				align-items: center
				justify-content: space-between

		@media (min-width: common.$break992)
			max-width: initial

			.label
				width: 53%
				margin-bottom: unset

			.select
				width: 45%
.label
	text-transform: uppercase
	font-family: inherit
	font-size: 24px

	@media (min-width: common.$break768)
		font-size: 30px
