$personHorizontalSpacing: 20px
$personVerticalSpacing: 40px

.wrapper
	max-width: 800px
	margin: auto

.list
	display: flex
	flex-direction: column

	@media (min-width: 400px)
		justify-content: center
		flex-direction: row
		flex-wrap: wrap
		margin: (-$personVerticalSpacing) (-$personHorizontalSpacing)

.person
	padding-left: $personHorizontalSpacing
	padding-right: $personHorizontalSpacing
	margin-top: $personVerticalSpacing
	margin-bottom: $personVerticalSpacing

	@media (min-width: 400px)
		width: 50%

	@media (min-width: 600px)
		width: 33%

	@media (min-width: 992px)
		width: 25%
