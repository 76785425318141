@use "common"

.wrapper

	@media (min-width: common.$break992)
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		z-index: 49
		background-color: rgba(common.$c-black, 0.9)
		visibility: hidden
		// hotfix: because of next.js image visibility: visible
		pointer-events: none
		opacity: 0
		transition-property: opacity, visibility
		transition-duration: .2s

		&.is_active
			visibility: inherit
			opacity: 1
			// hotfix: because of next.js image visibility: visible
			pointer-events: auto

.sublink
	&List
		display: none

		@media (min-width: common.$break992)
			display: block
			position: absolute
			top: var(--page-header-height)
			left: var(--page-desktop-menu-sidebar-width)
			right: 0
			bottom: 0
			color: common.$c-white

	&Item
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
