@use 'common'
@use 'sass:math'

$coverSliderRight: 100px
$coverSliderCardWidth: 430px
$coverSliderOverflow: 60px

.wrapper
	position: relative
	z-index: 0
	background-color: transparent

.pictures
	//

.overlay
	position: relative
	z-index: 3
	pointer-events: none

	@media (min-width: common.$break992)
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		display: flex
		justify-content: center
		align-items: center

		&In
			width: 100%
			display: flex
			justify-content: flex-end
			margin-left: auto
			margin-right: auto
			padding-right: $coverSliderRight

	@media (max-width: common.$break1600 - 1px)
		&In
			max-width: var(--site-width)
			padding-right: 0

.cards
	width: 100%
	display: flex
	flex-direction: column
	background: common.$c-white
	box-shadow: 5px 5px 30px rgb(0 0 0 / 5%), 2px 2px 10px rgb(0 0 0 / 5%)
	overflow: hidden
	pointer-events: auto

	@media (min-width: 992px)
		max-width: 430px

	&Slide
		flex-grow: 1
		flex-shrink: 1
		flex-basis: 400px
		opacity: 0
		transition: opacity ease-in-out .4s

.video
	&Button
		position: absolute
		bottom: 20px
		left: 20px
