@use 'common'

.wrapper
	display: flex
	justify-content: center
	flex-wrap: wrap

.section
	&Venue, &ShowType
		display: none
		justify-content: center
		align-content: center
		flex-wrap: wrap
		gap: 25px

		@media (min-width: common.$break768)
			gap: 50px

		&.is_inView
			display: flex

.price
	&Box
		display: flex
		flex-direction: column
		gap: 10px
		min-width: 10em
		padding: 25px 38px
		margin: 0
		text-align: center
		border: 1px solid common.$c-border2
		border-radius: 10px

		@media (min-width: common.$break768)
			flex-direction: column-reverse
			padding-top: 15px
			padding-bottom: 15px

	&Label
		color: #888
		line-height: 1.1

	&Number
		margin: 0
		font-size: 24px
		font-weight: 700
		line-height: 1

		@media (min-width: common.$break768)
			font-size: 50px
			font-weight: 400

	&Text
		border: 1px solid common.$c-border2
		padding: 2
