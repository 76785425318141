$videoTileVerticalSpacing: 20px
$videoTileHorizontalSpacing: 30px

.wrapper
	//

.switcher
	margin-bottom: 30px

.content
	padding-bottom: 50px
