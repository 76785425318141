@use 'common'

$betweenElementSpacing: 20px

.wrapper
	//

.main
	display: flex
	flex-direction: column
	align-items: center

	@media (min-width: common.$break992)
		flex-direction: row
		justify-content: space-between
		align-items: center
		gap: 3.125rem

.content
	align-self: flex-start

.name
	font-size: 18px
	margin-bottom: $betweenElementSpacing

	@media (min-width: common.$break768)
		font-size: 20px
		line-height: 1.1

.text
	font-size: 15px
	margin-bottom: $betweenElementSpacing

.link
	display: inline-block

.image
	display: flex
	justify-content: center
	align-items: center
	max-width: 15.625rem
	width: 100%
	flex-shrink: 0

.otherImages
	display: flex
	flex-wrap: wrap
	align-items: center
	justify-content: center
	gap: 20px
	margin-top: 40px

	@media (min-width: common.$break768)
		gap: 40px

	@media (min-width: common.$break992)
		justify-content: flex-start
