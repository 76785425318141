@use 'common'

.wrapper
	//

.main
	//

.event
	margin-bottom: 20px

	&:not(:first-child)
		margin-top: -1px // to hide double border
