@use 'common'

=link-hover-focus-style
	color: common.$c-main

$linkHorizontalSpacing: 10px
$linkVerticalSpacing: 10px

.wrapper
	display: flex
	justify-content: center

.link, .file
	display: inline-block
	color: common.$c-comp
	transition: color .2s
	text-decoration: unset

	&:hover
		+link-hover-focus-style

	&:focus-visible
		+link-hover-focus-style

.content
	display: flex
	color: inherit
	margin: (-$linkVerticalSpacing) (-$linkHorizontalSpacing)

.fileType
	display: inline-block
	padding: 2px 10px
	font-size: 16px
	margin-right: 10px
	border: 1px solid
	border-color: inherit
	border-radius: 4px
	text-transform: uppercase

.label
	line-height: 1.4
	color: inherit

	@media (min-width: common.$break768)
		font-size: 22px
