@use 'common'

.wrapper
	--container-horizontal-spacing: var(--page-horizontal-spacing)
	--container-vertical-spacing: var(--page-vertical-spacing)

	display: grid
	grid-template-columns: auto 1fr auto

.main
	grid-column: 2 // column in the middle
	max-width: calc(var(--container-width) + 2 * var(--container-horizontal-spacing))
	width: 100%
	margin-left: auto
	margin-right: auto
	padding: var(--container-vertical-spacing) var(--container-horizontal-spacing)

	&.view_height_full
		height: 100%

	&.view_size_normal
		--container-width: var(--content-width-normal)

	&.view_size_site
		--container-width: var(--site-width)

	&.view_size_wide
		--container-width: var(--content-width-wide)

	&.view_size_large
		--container-width: var(--content-width-large)

	&.view_disableGutters_horizontal, &.view_disableGutters_full
		--container-horizontal-spacing: 0px

	&.view_disableGutters_vertical, &.view_disableGutters_full
		--container-vertical-spacing: 0px
