@use "common"
@use "sass:math"

.wrapper
	width: 150px
	margin: auto
	text-align: center

.image
	//

.name
	font-size: 16px
	line-height: 1.3
	margin-top: 15px
	font-weight: normal
