@use 'common'

.wrapper

	&.is_repertoire
		:global
			.plyr__controls
				padding-left: 0

.navigation
	list-style: none
	display: flex
	flex-wrap: wrap
	gap: 10px

	&Item
		display: flex

	&Button
		display: flex
		align-items: center
		gap: 5px
		background-color: common.$c-white
		color: common.$c-main
		border: 1px solid common.$c-main
		cursor: pointer
		font-size: 18px
		line-height: 1.6
		transition-property: background-color, color
		transition-duration: .2s

		&::after
			content: ''
			display: inline-block
			width: 0
			height: 0
			border-top: 8px solid transparent
			border-bottom: 8px solid transparent
			border-left: 13px solid common.$c-main
			transition: border .2s

		&.is_active
			background-color: common.$c-main
			color: common.$c-white

			&::after
				border-left-color: common.$c-white

.audio
	margin-top: 20px
