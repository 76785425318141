@use 'common'

.wrapper
	//

.form
	display: flex
	flex-direction: column
	gap: 20px

.item
	font-family: common.$font-family-base

	&.is_select
		position: relative

.repertoireSelect
	position: absolute
	inset: 0
	opacity: 0
	pointer-events: none

.button
	display: flex
	justify-content: flex-end
