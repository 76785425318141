@use "common"

$itemVerticalSpacing: 30px

.wrapper
	display: flex
	flex-direction: column
	align-items: center

.item
	padding-top: $itemVerticalSpacing
	padding-bottom: $itemVerticalSpacing

	&:not(:first-child)
		border-top: 1px solid common.$c-grey-light3
