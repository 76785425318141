@use 'common'

$mainAndMetaSpacing: 20px
$tagVerticalSpacing: .2em
$tagHorizontalSpacing: .2em

.wrapper
	opacity: 0
	transform: translateY(200px)
	transition-property: transform, opacity
	transition-duration: .4s

	&.isInView
		opacity: 1
		transform: translateY(0)

	@media (min-width: common.$break768)
		display: flex

.meta
	position: relative
	text-align: center
	background-color: common.$c-comp
	color: common.$c-white
	padding: $mainAndMetaSpacing 10px

	@media (min-width: common.$break768)
		width: 120px
		display: flex

.date
	font-weight: 200

	&Short
		font-size: 20px
		display: inline-block
		padding-right: 3px

		@media (min-width: common.$break768)
			padding-right: 0
			font-size: 70px
			font-weight: 200
			line-height: 1

	&Long
		display: inline-block

		@media (min-width: common.$break768)
			margin-top: .5em

.main
	width: 100%
	padding: $mainAndMetaSpacing
	text-align: center
	border: 1px solid #cdcdcd

	@media (min-width: common.$break768)
		display: flex
		justify-content: space-between
		align-items: center

.info
	flex-grow: 1

	&Main
		display: flex
		align-items: center

.title
	margin: 0
	font-size: 24px
	font-weight: 300

	&In
		margin-right: 15px

	@media (min-width: common.$break480)
		font-size: 36px

	@media (min-width: common.$break768)
		text-align: left
		font-size: 50px

.schedule
	//

.description
	padding-top: 10px
	border-top: 1px solid common.$c-grey-light2
	margin-top: 10px

	&In
		margin-top: 20px
