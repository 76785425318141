@use 'common'

.wrapper
	width: 100%
	border-collapse: collapse

.row
	border-top: solid 1px common.$c-grey-light7
	font-size: 17px
	color: common.$c-black

	&:last-child
		border-bottom: solid 1px common.$c-grey-light7

.cell

	&Header
		//

	&Header, &Contents
		padding: .5em
		vertical-align: top

	&Header
		padding: .5em
		padding-left: 0

	&Contents
		padding-right: 0

	&Content
		color: common.$c-black

		a

			&:hover
				text-decoration: none
