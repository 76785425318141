@use 'common'

$personHorizontalSpacing: 20px
$personVerticalSpacing: 40px

.wrapper
	display: contents

.person
	margin-top: $personVerticalSpacing
	margin-bottom: $personVerticalSpacing
	transition: opacity .2s
	text-decoration: none
	color: common.$text-color
	scroll-margin: 50px
	outline: unset

	&.view_fade
		opacity: .2

.content
	position: relative
	grid-column: 1 / -1
	margin: 0 calc(-1 * var(--page-horizontal-spacing))
	background-color: common.$c-white

	&In
		padding: 40px 0 20px
