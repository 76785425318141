@use "common"

$aspectRatio: calc(var(--ImageAspectRatio-image-width) / var(--ImageAspectRatio-image-height))

=image-hover-focus-style
	.image, .emptyImage
		transform: translateY(-10px)

.wrapper
	//

.image
	position: relative
	transition: transform .2s

	&In
		&.is_intrinsic
			width: 100%
			height: auto

		&.is_fill
			object-fit: cover

.emptyImage
	min-width: 100px
	width: 100%
	position: relative
	overflow: hidden

	&::before
		content: ''
		display: block
		padding-top: 100%

	&In
		display: flex
		justify-content: center
		align-items: center
		flex-wrap: wrap
		width: 100%
		height: 100%
		position: absolute
		top: 0
		right: 0
		bottom: 0
		left: 0
		font-size: 1.5em
		color: common.$c-white

.emptyImage

	&::after
		content: ''
		// fallback background color is smaller size because it overflows the image which looks like border (happens with "rounded" style of image)
		position: absolute
		inset: 1px
		max-width: calc(var(--ImageAspectRatio-image-width) * 1px - 1px)
		max-height: calc(var(--ImageAspectRatio-image-height) * 1px - 1px)
		background-color: common.$c-grey
		z-index: -1

.view

	//style
	&_rounded
		.image, .emptyImage
			border-radius: 50%
			overflow: hidden

			&::after
				border-radius: 50%

	// layout
	&_default
		width: 100%

		&::before
			content: ''
			display: block
			padding-top: calc(100% / $aspectRatio)

	&_maxHeight
		width: min(250px, calc(var(--ImageAspectRatio-image-maxHeight) * $aspectRatio * 1px))
		aspect-ratio: $aspectRatio

		@media (min-width: common.$break480)
			width: 100%
			height: calc(var(--ImageAspectRatio-image-maxHeight) * 1px)

	&_squared
		&::before
			content: ''
			padding-top: 100%

	// hover
	&_verticalOffset

		a:hover &
			+image-hover-focus-style

		a:focus-visible &
			+image-hover-focus-style

		button:hover &
			+image-hover-focus-style

		button:focus-visible &
			+image-hover-focus-style

	&_transparent
		.image, .emptyImage
			background-color: transparent

			&::after
				background-color: transparent
