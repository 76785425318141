@use 'common'
@use './GridTileFull.module' as gridTileFull

.wrapper
	//

.ratio
	--CoverSliderPicture-slider-ratio: (9 / 16)

	&In
		position: relative
		height: 0
		padding-top: calc(var(--CoverSliderPicture-slider-ratio) * 100%)
		background-color: common.$c-black

	@media (min-width: common.$break992)
		max-width: var(--site-width-ratio)
		width: calc((100% - var(--site-width)) / 2 + (var(--site-col-width)))

	@media (min-width: common.$break1600)
		max-width: none
		width: auto
		margin-right: gridTileFull.$coverSliderRight + gridTileFull.$coverSliderCardWidth - gridTileFull.$coverSliderOverflow

	@media (max-aspect-ratio: 16/8) and (max-width: common.$break1600 - 1px)
		--CoverSliderPicture-slider-ratio: (747 / 960)

.image
	object-fit: cover
