@use 'common'

.wrapper
	display: flex
	align-items: center
	background-color: common.$c-grey-light9
	padding: 12px 30px
	gap: 30px

.title
	color: inherit
	font-size: 16px
	line-height: 1.375
	font-weight: 400
	margin: unset

.list
	display: flex
	flex-wrap: wrap
	align-items: center
	gap: 20px
	list-style-type: none
