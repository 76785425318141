@use 'common'

.wrapper
	//

.content
	padding-bottom: common.$siteContentPaddingBottom

.main
	padding: 25px
