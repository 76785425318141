@use 'common'
@use 'sass:math'

.wrapper
	//

.main
	@media (min-width: common.$break768)
		display: flex
		gap: 20px

.title
	font-size: 42px
	text-align: center

	@media (min-width: common.$break768)
		display: flex
		align-items: center
		justify-content: center
		min-width: 150px
		max-width: 200px

.list
	display: grid
	grid-template-columns: 1fr
	gap: 10px
	flex-grow: 1

	@media (min-width: common.$break480)
		grid-template-columns: 1fr 1fr

	@media (min-width: common.$break768)
		grid-template-columns: 1fr 1fr 1fr

.item
	$aspectRatio: math.div(16, 9)

	width: 100%
	position: relative

	&::before
		content: ''
		display: block
		padding-top: math.div(100%, $aspectRatio)

.footer
	display: flex
	justify-content: flex-end
	margin-top: 30px

.link
	font-size: 15px
	font-weight: 700
	display: inline-block

	&:hover, &:focus
		text-decoration: none
