@use 'common'
@use 'sass:color'

$iconTranslateWidth: 0.15em

.wrapper
	display: flex
	align-items: center
	position: relative
	height: 100%
	width: 100%
	padding: 1.2em 1.8em
	border: none
	background-color: common.$c-white
	margin: 0
	font-size: inherit
	font-weight: inherit
	text-align: inherit
	color: var(--Button-other-color)
	cursor: pointer
	text-transform: inherit
	text-decoration: none
	border-radius: 5px
	border: 1px solid var(--Button-base-color)
	box-shadow: 0px 4px 5px 0px rgba(0, 0, 0, 0.15)
	font-weight: 600
	line-height: 1
	text-align: center
	transition: background-color .2s
	outline: none
	font-family: common.$font-family-base

	.icon
		display: none

	&.small
		padding: 0.3em 0.3em 0.3em 0.6em
		background-color: unset
		border: 1px solid common.$c-red
		box-shadow: none
		width: auto

		.titleBox
			justify-content: center
			color: common.$c-red

			.title
				padding: 0.25em 0
				padding-right: 0.3em
				font-size: 1em
				font-weight: 400

				@media (min-width: common.$break992)
					padding-right: 20px
					font-size: 1.3em

		.children
			padding: 0

		@media (min-width: common.$break992)
			width: fit-content
			padding: 0.25em 0.6em 0.25em 2em

	&:hover
		.titleBox
			color: common.$c-red

		.radioButton
			border-color: common.$c-red

	&.is_active
		background-color: common.$c-red
		color: common.$c-white

		.icon
			display: inline-flex

			&.up
				transform: translateY(-1 * $iconTranslateWidth)

			&.right
				transform: translateX($iconTranslateWidth)

			&.down
				transform: translateY($iconTranslateWidth)

			&.left
				transform: translateX(-1 * $iconTranslateWidth)

		.children
			display: block
			padding-top: 1em
			color: common.$c-white
			font-size: 0.875em
			font-weight: 300

			a
				color: common.$c-white

			@media (min-width: common.$break992)
				font-size: 1.45em

		.radioButton
			border-color: common.$c-white
			background-color: common.$c-white

		&.small
			.radioButton
				width: 17px
				height: 17px

				.icon > svg
					height: 0.6em

					@media (min-width: common.$break992)
						height: unset

				@media (min-width: common.$break992)
					width: 26px
					height: 26px

		&:hover
			.titleBox
				color: common.$c-white

			.radioButton
				border-color: common.$c-white
				background-color: common.$c-white

	&.is_disabled
		&:hover, &.is_active
			background-color: var(--Button-base-color)
			cursor: not-allowed

		&:focus-visible
			background-color: var(--Button-base-color)
			cursor: not-allowed

		&.is_outline
			background-color: transparent
			color: var(--Button-base-color)
			border: 1px solid var(--Button-base-color)

		&.is_disabled
			&:hover, &.is_active
				background-color: transparent
				cursor: not-allowed

			&:focus-visible
				background-color: transparent
				cursor: not-allowed

	&.is_bordered
		background-color: transparent
		border: solid 1px

		&.is_disabled
			&:hover, &.is_active
				background-color: transparent
				cursor: not-allowed

			&:focus-visible
				background-color: transparent
				cursor: not-allowed

	&.is_shadowed
		box-shadow: 0 2px 16px rgba(#000000, .2), 0 2px 4px rgba(#000000, .2)

	&:focus-visible
		.icon

			&.isLooped
				&.down
					animation-name: shiftDown
					animation-timing-function: ease
					animation-duration: 1s

	&.is_outline
		background-color: transparent
		color: var(--Button-base-color)
		border: 1px solid var(--Button-base-color)

		&.is_disabled
			&:hover, &.is_active
				background-color: transparent
				cursor: not-allowed

			&:focus-visible
				background-color: transparent
				cursor: not-allowed

	&.is_bordered
		background-color: transparent
		border: solid 1px

	&.is_shadowed
		box-shadow: 0 2px 16px rgba(#000000, .2), 0 2px 4px rgba(#000000, .2)

	&.is_sidespace
		padding: .5em 2.5em .6em

	&.is_borderRadiusUnset

		&All
			border-radius: unset
		&Top
			border-top-right-radius: unset
			border-top-left-radius: unset
		&Right
			border-top-right-radius: unset
			border-bottom-right-radius: unset
		&Bottom
			border-bottom-right-radius: unset
			border-bottom-left-radius: unset
		&Left
			border-top-left-radius: unset
			border-bottom-left-radius: unset

	&.is_disabled
		&:hover, &.is_active
			background-color: var(--Button-base-color)
			cursor: not-allowed

		&:focus-visible
			background-color: var(--Button-base-color)
			cursor: not-allowed

	&.slug
		.children
			padding: 0

		&.is_active
			.title
				color: white
				padding-right: 10px

	@media (min-width: common.$break992)
		width: 320px
		padding: 1.4em 1.8em

.content
	text-align: start
	width: 100%

.titleBox
	display: flex
	justify-content: space-between
	align-items: center

.title
	font-size: 1.125em
	font-weight: 700
	line-height: 1.2

	&::first-letter
		text-transform: capitalize

	@media (min-width: common.$break992)
		font-size: 1.6em

.buttonTitle
	display: flex
	width: 100%
	justify-content: space-between

.radioButton
	display: flex
	align-items: center
	justify-content: center
	width: 26px
	height: 26px
	border: 1px solid common.$c-grey-light5
	border-radius: 50%

.children
	display: none

	@media (min-width: common.$break992)
		display: block
		padding-top: 1em
		color: common.$c-grey-light5
		font-weight: 300
		font-size: 1.45em

		a
			color: common.$c-grey-light5

.linkWrapper
	position: relative
	box-shadow: none

	.cancelField
		position: absolute
		top: 0.85rem
		right: 1rem
		display: flex
		justify-content: flex-end
		width: fit-content
		z-index: 2

		@media (min-width: common.$break992)
			top: 1.3rem
			right: 1.3rem

		.active
			border-color: common.$c-white
			background-color: common.$c-white

			.icon
				display: inline-flex
