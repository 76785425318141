@use 'common'

$elementVerticalSpacing: 10px

.wrapper
	display: flex
	align-items: center
	gap: 10px
	color: common.$c-black

.meta
	width: 120px
	text-align: center

.date

	&Short
		font-size: 40px
		line-height: 1

	&Long
		margin-top: $elementVerticalSpacing

.tag
	margin-top: $elementVerticalSpacing

.main
	width: 100%
	display: flex
	flex-direction: column
	gap: 10px

	&In
		display: flex
		flex-wrap: wrap
		gap: 12px
		justify-content: space-between

.title
	font-size: 24px
	font-weight: 600
	margin: 0

	&:first-letter
		text-transform: uppercase

.place
	display: flex
	align-items: center
	font-size: 14px
	color: inherit
	gap: .5em

	&VerticalDivider
		flex-shrink: 0
		width: 1px
		height: 22px
		background-color: common.$c-black

.venue
	flex-grow: 1

.actions
	display: flex

.button
	font-size: 1.5em
