@use "common"

=link-hover-focus-style
	transform: translateY(-10px)

.wrapper
	width: 100%
	height: 100%

.main
	height: 100%
	padding: var(--page-vertical-spacing) var(--page-horizontal-spacing)
	overflow-y: auto

.grid
	height: calc(100% - var(--page-header-height) - var(--page-vertical-spacing))
	display: grid
	grid-template-columns: repeat(3, 1fr)
	grid-auto-rows: minmax(25vh, 30vh)
	gap: 20px
	justify-content: center

	@media (max-height: 600px), (min-width: 2048px)
		grid-template-columns: repeat(4, 1fr)

.item
	width: 100%
	text-align: center
	visibility: hidden
	opacity: 0
	transition-property: visibility, opacity, transform
	transform: scale(.6)
	transition-duration: .4s
	transition-delay: 0s

	&.is_visible
		visibility: initial
		opacity: 1
		transition-delay: calc(.05s * var(--HeaderMainNavigationSublinks-item-index))
		transform: scale(1)

.link
	display: inline-block
	height: 100%
	text-decoration: none
	outline: none
	transition: transform .2s

	&:hover
		+link-hover-focus-style

	&:focus-visible
		+link-hover-focus-style

.figure
	flex-direction: column
	margin: 0

	&Caption
		margin-top: 10px
		color: common.$c-white

.image
	width: calc(10vh + 5vw)
	max-height: 25vh
	margin: auto
	border: none

	@media (max-height: 600px)
		width: calc(10vh + 4vw)

	@media (min-width: 2048px)
		width: calc(10vh + 3vw)
