@use 'common'

.wrapper
	width: 100%
	@media (min-width: common.$break768)
		width: 85%
		padding-left: 0.2em
		padding-right: 0.2em

	&.is_hidden
		display: none

	&.view_inline
		border: initial
		padding: 0
		width: fit-content
.box
	padding: 1em 0em
	@media (min-width: common.$break768 )
		padding: 1em 2em

.shadow
	box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2)
	border-radius: 10px
	padding: 1em 2em

.title
	font-size: 16px
	font-weight: 400
	line-height: 1.3

.form
	position: relative

.termsAndAgreements
	margin: 1.2em 0

.gdpr
	margin-top: 1em
	font-size: 11px
	color: common.$c-grey-light5

.textInfo
	margin-top: 1em

	@media (min-width: common.$break768)
		padding-left: 2em

.field
	&Names
		@media (min-width: common.$break480)
			display: flex
			gap: 12px

.ticket
	$ticket: &

	&Button
		display: flex
		align-items: center
		background-color: transparent
		border: initial
		padding: 0
		padding-top: 0.5em
		cursor: pointer

		&:hover, &:focus
			#{$ticket}
				&Label
					text-decoration: none

		@media (min-width: common.$break768)
			padding-left: 2em
	&Label
		margin-left: 1em
		text-align: left
		line-height: 1.4
		font-weight: 400
		text-decoration: underline
		color: common.$c-main
