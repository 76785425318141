@use 'common'
@use 'sass:math'

.wrapper
	height: 100%
	position: relative

	&.view_small, &.view_medium, &.view_large
		.content
			aspect-ratio: 325 / 400

	@media (min-width: common.$break480)

		&.view_medium, &.view_large
			.content
				aspect-ratio: 650 / 400

	@media (min-width: common.$break992)
		&.view_large
			.content
				aspect-ratio: 975 / 400

.main
	display: grid
	gap: 20px

	@media (min-width: common.$break480)
		grid-template-columns: 1fr 1fr

	@media (min-width: common.$break992)
		grid-template-columns: 1fr 1fr 1fr

.background
	position: absolute
	inset: 0
	z-index: 0

	&::before
		content: ''
		position: absolute
		inset: 0
		z-index: 1
		background: linear-gradient(0deg, common.$c-black, transparent 60%)

.image
	object-fit: cover

.content
	width: 100%
	height: 100%
	position: relative
	display: flex
	flex-direction: column
	justify-content: flex-end
	padding: 1em
	z-index: 1
	color: common.$c-white

.title
	font-size: 18px
	margin-bottom: 12px

.button
	display: flex
	align-items: stretch

.actions
	margin-top: 12px
	display: flex
	justify-content: space-between
	gap: 10px
