@use 'common'

.wrapper
	display: flex
	flex-direction: column

	@media (min-width: common.$break992)
		flex-direction: row
		flex-wrap: wrap
		gap: 3rem

.tile
	margin-bottom: 25px

	@media (min-width: common.$break992)
		flex: 1 1 40%
